export const contactsData = [
  {
    icon: 'FaGithub',
    color: '',
    size: '20',
    url: 'https://github.com/SopanhaKok',
  },
  {
    icon: 'FaTelegram',
    color: '#0088cc',
    size: '20',
    url: 'https://t.me/Panha_KS',
  },
]
