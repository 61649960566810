export const skillsData = [
  {
    url: 'https://go.dev/blog/go-brand/Go-Logo/PNG/Go-Logo_Blue.png',
    text: 'Go',
    size: '20',
    color: '#FF2D20',
  },
  {
    url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/1024px-Tailwind_CSS_Logo.svg.png',
    text: 'Tailwind',
    size: '20',
    color: '#E44F26',
  },
  {
    icon: 'FaJs',
    text: 'Javascript',
    size: '20',
    color: '#f0db4f',
  },
  {
    icon: 'FaReact',
    text: 'React js',
    size: '20',
    color: '#88dded',
  },
  {
    icon: 'FaVuejs',
    text: 'Vue js',
    size: '20',
    color: '#88dded',
  },
  {
    icon: 'FaPython',
    text: 'Python',
    size: '20',
    color: '#88dded',
  },
  {
    icon: 'FaNode',
    text: 'Node JS',
    size: '20',
    color: '#539E43',
  },
  {
    url: 'https://cdn.worldvectorlogo.com/logos/express-109.svg',
    text: 'Express JS',
    size: '20',
    color: '#FF2D20',
  },
  {
    icon: 'FaLaravel',
    text: 'Laravel',
    size: '20',
    color: '#FF2D20',
  },

  {
    url: 'https://w7.pngwing.com/pngs/74/362/png-transparent-typescript-plain-logo-icon-thumbnail.png',
    text: 'Typescript',
    size: '20',
    color: '#FF2D20',
  },
  {
    // icon: 'FaLaravel',
    url: 'https://nuxt.com/assets/design-kit/icon-green.svg',
    text: 'Nuxt JS',
    size: '20',
    color: '#FF2D20',
  },
  {
    // icon: 'FaLaravel',
    url: 'https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png',
    text: 'Next JS',
    size: '20',
    color: '#FF2D20',
  },
  {
    // icon: 'FaLaravel',
    url: 'https://caprover.com/img/logo.png',
    text: 'Caprover',
    size: '20',
    color: '#FF2D20',
  },
  {
    // icon: 'FaLaravel',
    url: 'https://cdn.worldvectorlogo.com/logos/aws-2.svg',
    text: 'AWS S3',
    size: '20',
    color: '#FF2D20',
  },
  {
    // icon: 'FaLaravel',
    url: 'https://cdn.worldvectorlogo.com/logos/aws-2.svg',

    text: 'AWS Route53',
    size: '20',
    color: '#FF2D20',
  },
  {
    // icon: 'FaLaravel',
    url: 'https://cdn.worldvectorlogo.com/logos/aws-2.svg',

    text: 'AWS EC2',
    size: '20',
    color: '#FF2D20',
  },
  {
    url: 'https://cdn.worldvectorlogo.com/logos/redis.svg',
    text: 'Redis',
    size: '20',
    color: '#FF2D20',
  },
  {
    url: 'https://upload.wikimedia.org/wikipedia/commons/d/d5/Selenium_Logo.png',
    text: 'Selenium',
    size: '20',
    color: '#FF2D20',
  },
  {
    url: 'https://static-00.iconduck.com/assets.00/puppeteer-icon-1371x2048-otngklvq.png',
    text: 'Puppeteer',
    size: '20',
    color: '#FF2D20',
  },
  {
    url: 'https://upload.wikimedia.org/wikipedia/labs/8/8e/Mysql_logo.png',
    text: 'MySQL',
    size: '20',
    color: '#FF2D20',
  },
  {
    url: 'https://cdn.worldvectorlogo.com/logos/postgresql.svg',
    text: 'PostgreSQL',
    size: '20',
    color: '#FF2D20',
  },
]
